.homePage {
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5%;
  margin-bottom: 15%;
}

.containerHome {
  margin-right: 10%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.profileImg {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.functionLabel {
  width: 100%;
  color: #fecc0d;
  font-weight: bold;
}

.configButton {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  border: none;
}

.missionsCard {
  background-color: #0314af;
  color: white;
  padding: 20px;
  border-radius: 30px;
  margin-right: 10%;
  margin-left: 10%;
}

.missionsCard input {
  width: 100%;
  margin-bottom: 10px;
}

.inputText {
  border: none;
  border-bottom: 2px solid white;
  outline: none;
  min-width: 300px;
  background: none;
  color: white;
}

.buttonMissions {
  width: 100%;
  min-height: 40px;
  border: none;
  background-color: #fecc0d;
  font-size: 16px;
  color: #0314af;
  height: auto;
  border-radius: 30px;
  font-weight: bold;
  margin-top: 1%;
}