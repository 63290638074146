.userList {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    padding: 1%;
    border-radius: 50px;
    margin: 1%;
}

.userActive{
    background-color: #0314af;
}

.userInactive{
    background-color: #2e2e2e;
}

.profilesImg {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
    margin: 1%;
}

.offlineIndicator {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 30px;
    height: 30px;
    background-color: red;
    border-radius: 50%;
}

.onlineIndicator {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 30px;
    height: 30px;
    background-color: green;
    border-radius: 50%;
}

.configsButton{
    color: white;
    background: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 1%;
    border: none;
    font-size: 36px;
}