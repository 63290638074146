/* chat.css */

.chat-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.chat-messages {
    flex: 1;
    overflow-y: auto; /* Adicionando uma barra de rolagem vertical */
    padding: 10px;
    background-color: white;
    border-radius: 30px;
    margin-right: 10%;
    margin-left: 10%;
    max-height: 400px; 
    min-height: 400px; 
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 5%;
}

/* Adicionando as pseudo-classes do WebKit com prefixos */
.chat-messages::-webkit-scrollbar {
    width: 8px;
}

.chat-messages::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.chat-messages::-webkit-scrollbar-track {
    background-color: transparent;
}



.message {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 8px;
    background-color: #f0f0f0;
    max-width: 70%;
}

.message strong {
    color: #333;
    margin-right: 5px;
}

.message .timestamp {
    width: 100%;
    margin-top: 1%;
    color: #ffffff;
    font-size: 12px;
    display: flex;
    justify-content: end;
}

.message.sent {
    align-self: flex-end;
    background-color: #007bff;
    color: #fff;
    border-radius: 20px 20px 0 20px;
    transform: skew(10deg);
}

.message.sent .message-content {
    transform: skew(-10deg);
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
    align-items: start;
}


.message.received {
    align-self: flex-end;
    background-color: #797979;
    color: #fff;
    border-radius: 20px 20px 20px 0px;
    transform: skew(-10deg);
}

.message.received .message-content {
    transform: skew(10deg);
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.input-container {
    display: flex;
    align-items: center;
    padding: 10px;
}

.input-container input[type="text"] {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
}

.input-container button {
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
}

.input-container button:hover {
    background-color: #0056b3;
}

.align-right {
    width: 100%;
    display: flex;
    justify-content: end;
}

.align-left {
    width: 100%;
    display: flex;
    justify-content: start;
}

.headerChat {
    top: 0;
    right: 0;
    left: 0;
    position: absolute;
    background-color: #0314af;
    border-radius: 30px;
    z-index: 1;
    display: flex;
    align-items: center;
    flex-direction: row;
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.562);
    padding: 1%;
}


.userList {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    padding: 1%;
    border-radius: 50px;
    margin: 1%;
}

.userActive {
    background-color: #0314af;
}

.userInactive {
    background-color: #2e2e2e;
}

.profilesImg {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
    margin: 1%;
}

.offlineIndicator {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 30px;
    height: 30px;
    background-color: red;
    border-radius: 50%;
}

.onlineIndicator {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 30px;
    height: 30px;
    background-color: green;
    border-radius: 50%;
}

.configsButton {
    color: #fecc0d;
    background: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 1%;
    border: none;
    font-size: 25px;
}