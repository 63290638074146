.schedulePage {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: black;
    overflow-x: auto;
    margin-bottom: 15%;
}


.monthBanner {
    color: white;
    background-color: #173f74;
    width: 100%;
    padding: 1%;
}


table {
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    margin-bottom: 120px;
    overflow-x: auto;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    word-break: break-word; 
}

th {
    background-color: #f2f2f2;
    color: black;
    text-align: center;
}
