.addEvent {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;  
    background-color: rgba(0, 0, 0, 0.6);  
}

.btnCircle {
    border-radius: 100%;
    width: 60px;
    height: 60px;
    background-color: #ffd400;
    color: black;
    border: none;
    cursor: pointer;
    box-shadow: 0 8px 16px rgba(0, 10, 20, 0.6);
    margin-top: 1%;
    margin-bottom: 40px;
    margin-right: 1%;
    margin-left: 1%;
}

.btnAdd:hover {
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.6);
    /* Adiciona uma sombra suave */
}