.missionCreationPage {
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5%;
  margin-bottom: 15%;
}

.missionPage {
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5%;
  margin-bottom: 5%;
}

.containerMission {
  margin-right: 10%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.missionsCard {
  background-color: #0314af;
  color: white;
  padding: 20px;
  border-radius: 30px;
  margin-right: 10%;
  margin-left: 10%;
}

.inputText {
  border: none;
  border-bottom: 2px solid white;
  outline: none;
  min-width: 300px;
  background: none;
  color: white;
}

.selectContainer {
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.selectContainer select {
  border: none;
  border-bottom: 2px solid white;
  outline: none;
  background: none;
  color: white;
  width: 100%;
  padding: 8px 0;
  font-size: 16px;
  appearance: none;
  /* Remove o estilo padrão do select */
  background-color: #0314af;
}

.selectContainer::after {
  content: '\25BC';
  /* Ícone de seta para baixo */
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  pointer-events: none;
}


.textareaContainer {
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.textareaContainer textarea {
  border: none;
  border-bottom: 2px solid white;
  outline: none;
  background: none;
  color: white;
  width: 100%;
  padding: 8px 0;
  font-size: 16px;
  resize: vertical; /* Permite redimensionamento apenas na direção vertical */
  max-height: 60px;
}


.selectedUsersContainer {
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.selectedUsersContainer .user-select {
  border: none;
  border-bottom: 2px solid white;
  outline: none;
  background: none;
  color: white;
  width: 100%;
  padding: 8px 0;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  max-height: 40px;
  overflow-y: auto;
}

.selectedUsersContainer .user-select input[type="checkbox"] {
  margin-right: 8px;
}

.row{
  width: 100%;
  display: flex;
  flex-direction: row;
}

.checkbox{
  max-width: 10%;
}


.buttonMissions {
  width: 100%;
  min-height: 40px;
  border: none;
  background-color: #fecc0d;
  font-size: 16px;
  color: #0314af;
  height: auto;
  border-radius: 30px;
  font-weight: bold;
  margin-top: 1%;
}

.functionLabel {
  width: 100%;
  color: #fecc0d;
  font-weight: bold;
}

.fileInputContainer {
  position: relative;
  width: 100%;
}

.fileInputContainer input[type="file"] {
  border: none;
  border-bottom: 2px solid white;
  outline: none;
  background: none;
  color: white;
  width: 100%;
  padding: 8px 0;
  font-size: 16px;
}


