.missionsPage {
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5%;
  margin-bottom: 15%;
}

.containerMissions {
  margin-right: 10%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Img {
  width: 150px;
  height: 150px;
}

.buttonMissions {
  width: 100%;
  min-height: 40px;
  border: none;
  background-color: #fecc0d;
  font-size: 16px;
  color: #0314af;
  height: auto;
  border-radius: 30px;
  font-weight: bold;
  margin-top: 1%;
}

.button {
  background-color: #0314af;
  font-size: 16px;
  color: white;
  height: 80px;
  font-size: 26px;
  border-radius: 30px;
  font-weight: bold;
  margin-top: 1%;
  border: none;
}

.functionLabel {
  width: 100%;
  color: #fecc0d;
  font-weight: bold;
}