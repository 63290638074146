.homePage {
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5%;
  margin-bottom: 15%;
}

.containerHome {
  margin-right: 10%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.missionsCard {
  background-color: #0314af;
  color: white;
  padding: 20px;
  border-radius: 30px;
  margin-right: 10%;
  margin-left: 10%;
}

.functionLabel {
  width: 100%;
  color: #fecc0d;
  font-weight: bold;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 20%;
  font-size: 30px;
  color: white;
}

.button-container button {
  border-radius: 100%;
  padding: 5%;
  margin: 0 10px;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 80px;
  transition: transform 0.3s;
}

.button-container button.accept {
  background-color: #28a745;
}

.button-container button.reject {
  background-color: #dc3545;
}

.button-container button:hover {
  transform: translateY(-3px);
}
