.chatPage {
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5%;
  margin-bottom: 20%;
}

.containerChat {
  margin-right: 10%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.LogoJA {
  width: 270px;
  height: 270px;
}